// Scaffolding
body {
  overflow-x: hidden;
}


// Euclid Circular A - Font import
@font-face {
  font-family: 'Euclid Circular A';
  src: url($assets-path + 'euclidcirculara-bold.woff2') format('woff2'),
       url($assets-path + 'euclidcirculara-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Euclid Circular A';
  src: url($assets-path + 'euclidcirculara-regular.woff2') format('woff2'),
       url($assets-path + 'euclidcirculara-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


// Header Layout
.pageHeader .headerContent { position: relative; }
.pageHeader .headerContent:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  display: block;
  height: 4px;
  width: 100vw;
  background: $_blue;
}


// Header sticky - Layout
.pageHeader.sticky {
  z-index: 4 !important;
  .headerContent {
    padding-top: #{$spacer * 0.5};
    padding-bottom: #{$spacer * 0.5};
  }
}


// Header Mobile - Layout
@media (max-width: $logo-breakpoint) {
  .pageHeader .headerContent {
    padding-top: #{$spacer * 0.5};
    padding-bottom: #{$spacer * 0.5};
  }
}

// Mobile logo - layout
@media (max-width: $cta-btn-breakpoint) {
  .pageHeader .headerContent {
    padding-left: #{$site-bleed / 2};
    padding-right: #{$site-bleed / 2};
  }

  .pageHeader.sticky .mainLogo {
    width: 42px;
    height: 38px;
  }
  .menuMainAlt {
    font-size: $font-size-small;
  }
}


// Burger button
.menuMainAlt.active {
  background-color: $nav-top-level-item-background-colour;
  color: $nav-top-level-item-hover-colour;
  margin-top: 0;
  margin-bottom: 0;
}


// Navigation - Layout
.menuMain.active {
  z-index: 5;
  padding: 17px 0;
  // Nav format
  .topLevel > li > a {
    padding: $nav-top-level-item-padding;
    &:hover, &.active { background-color: $nav-top-level-item-hover-background-colour; }
  }
  // Social Icons
  .socialIcons {
    margin-bottom: 0;
    padding: 12.5px 15px;
  }
}
ul.subMenu {
  padding: 6px 0;
}


// Nav search - Layout
.menuMain .header-search {
  padding: $nav-top-level-item-padding;
}


// Get help button - Format
.sticky-button {
  @include button (
    $btn-background-colour: $_orange,
    $btn-text-colour: $body-colour,
    $btn-border-radius: 95% 0 0 0,
    $btn-padding-y: 1em
  );
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  min-height: 130px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  transform-origin: bottom right;
  // Icon
  &:before {
    content: '';
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    background-image: url($assets-path + "chat-icon.svg");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    margin-bottom: #{$spacer / 2};
  }
  // Hover effectt
  &:hover { 
    transform: scale(1.050);
  }

  // Responsive
  @media (max-width: map-get($breakpoints, md)) {
    padding: 0.5em;
    width: 100%;
    min-height: auto;
    border-radius: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // Icon
    &:before {
      width: 30px;
      height: 30px;
      margin-bottom: 0;
    }
    // Hover effectt
    &:hover {
      transform: none;
    }
  }
}


// Donate button - Format
a.cta-button.donate:hover,
a.button.donateBtn:hover,
button.register:hover,
button.donate:hover {
  background-color: $_red;
  color: $white;
}


// Carousel - Glitch fix
.carousel * {
  transition: 0s !important;
  opacity: 1;
}


// Carousel heading - Underline
.carouselSlide .carouselSlideHeading:after {
  content: '';
  display: block;
  height: 5px;
  width: 3em;
  background: $_blue;
  background-size: 200%;
  background-position: left;
  border-radius: $border-radius;
  margin: #{$spacer * 0.5} 0 #{$spacer};
}


// Carousel heading - Load
.carousel:not(.carouselImageSingle) .carouselSlide:not(.hidden) .carouselSlideHeading:after {
  width: 0;
  animation-name: load;
  animation-duration: 8000ms; 
  animation-timing-function: linear;
  animation-delay: 0;
}


@keyframes load {
  0% { width: 0; }
  100% { width: 200px; }
}


// Desktop carousel details - Background
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselSlideTitle:not(.showsVideo) {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100.5%;
      height: 100.5%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background-image: url($assets-path + "curve-bottom-blue.png");
      background-image: url($assets-path + "curve-bottom-blue-deep.png");
      background-position: bottom -1.5px center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 1;
    }
  }

  // Carousel details - Spacing
  .carouselSlideDetail {
    margin-top: #{$spacer * -2};
  }

  // Inner page carousel
  body:not(.homepage) .carouselSlideTitle:not(.showsVideo):before {
    // background-image: url($assets-path + "curve-bottom-white.png");
    background-image: url($assets-path + "curve-bottom-white-deep.png");
  }
}


// Mobile carousel - Layout
@media (max-width: $carousel-breakpoint) {
  .carousel { z-index: 1; }
  .carouselSlide { overflow: visible; }
  .carouselSlideDetail {
    border-radius: 0;
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 45px;
      left: 50%;
      transform: translateX(-50%);
      background-size: 100% auto;
      background-repeat: no-repeat;
      z-index: 1;
    }
    &:before {
      bottom: 100%;
      background-image: url($assets-path + "carousel-mobile-top-blue-light.png");
      background-position: bottom -1.5px center;
    }
    &:after {
      top: 100%;
      background-image: url($assets-path + "carousel-mobile-bottom-blue-light.png");
      background-position: top -1.5px center;
    }
  }

  // Carousel heading - Bar
  .carouselSlide:not(.hidden) .carouselSlideHeading:after {
    background: $carousel-mobile-heading-colour;
  }

  // Carousel arrows - Spacing
  .carouselControls {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $spacer;
  }
}


// Carousel inner pages
body.page-has-banner:not(.homepage) .carousel:not(.carouselImageMultiple):after {
  content: '';
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  max-height: 320px;
  background-image: url($assets-path + "curve-bottom-white.png");
  background-position: bottom -1.5px center;
  background-repeat: no-repeat;
  background-size: 100% auto; 
  z-index: 1;
  pointer-events: none;
}


// Home Feature - Background
// .homeFeaturesWrapper {
//   background-image: url($assets-path + "curve-top-blue.png");
//   background-position: top -1.5px center;
//   background-size: 100.5% auto;
//   background-repeat: no-repeat;
// }


// homeBoxes + feedItem - Layout
.homeFeatureDetailsWrapper,
.feedItemDetailsWrapper,
.listedPostText {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  border-radius: $border-radius;
  h2:after, h3:after {
    content: '';
    display: block;
    width: 3.5em;
    height: 5px;
    background: $_gradient-blue;
    background-size: 200%;
    background-position: left;
    border-radius: $border-radius;
    margin: #{$spacer * 0.5} 0 #{$spacer * 0.75};
    transition: 300ms all ease;
  }
}

// Card specific - Format
.YourstoriesFeed .feedItemDetailsWrapper h3:after {
  background: $_gradient-purple;
}

// homeBoxes + feedItem - Layout hover
.homeFeatures .homeFeature:hover .homeFeatureDetailsWrapper,
.feedItem:hover .feedItemDetailsWrapper,
.listedPost:hover .listedPostText {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  h2:after, h3:after {
    transition: 300ms all ease;
    width: 7em;
    background-size: 100%;
  }
}

// homeBoxes + feedItem - Layout hover
.homeFeatures .homeFeature:hover [class*="image-link"] + div,
.homeFeatures .homeFeature:hover [class*="image-link"] + div + footer,
.feedItem:hover [class*="image-link"] + div,
.feedItem:hover [class*="image-link"] + div + footer,
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):hover [class*="image-link"] + div,
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):hover [class*="image-link"] + div + footer {
  transform: translateY(-1.25rem) !important; 
}


// Features - Layout
.homeFeatures {
  z-index: 2;
  position: relative;
}
  

// Feeds layout - See all button
.homeFeed {
  position: relative;
  max-width: $home-features-max-width;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  .feedsTitle { flex: 1; }
  .feedList { z-index: 1; }

  // See all button - Format
  .feedSeeAll {
    flex: unset;
    margin-left: auto;
    margin-right: #{$gap-width / 2};
    margin-bottom: $spacer;
    width: auto;
  }
  
  // Feed layout - Mobile
  @media (max-width: map-get($breakpoints, sm)) {
    .feedsTitle { order: 1; }
    .feedList { order: 2; }
    .feedSeeAll {
      order: 3;
      margin-left: #{$card-gap-width / 2};
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}


// News feed
.homeFeed {
  .feedSeeAll {
    @include button(
      $btn-background-colour: $_blue,
      $btn-text-colour: text-contrast($_blue)
    );
  }

  // Background - Plain colour
  &:before, &:after {
    content: '';
    position: absolute;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    pointer-events: none;
    background-repeat: no-repeat;
  }

  // Story feed 
  &.YourstoriesFeed {
    .feedSeeAll {
      @include button(
        $btn-background-colour: $_purple
      );
    }

    // Background - Setup
    &:before, &:after {
      height: 100%;
      background-size: 100.5% auto;
    }

    // Background - Wave
    &:before {
      top: 0;
      bottom: auto;
      background-image: url($assets-path + "feed-curve-bottom-grey.png");
      background-position: top 10px center;
      background-size: 100.5% calc(100% - 10px);
      z-index: -2;
      // Mobile wave
      @media (max-width: map-get($breakpoints, md)) {
        background-image: url($assets-path + "feed-mobile-curve-bottom-grey.png");
        background-position: top 20px center;
      }
    }

    // &:after {
    //   top: auto;
    //   bottom: 0;
    //   background-image: url($assets-path + "curve-bottom-blue-dark.png");
    //   background-position: bottom -1.5px center;
    //   background-size: 100.5% auto;
    //   z-index: -1;
    // }
  }
}

// Other feeds - Layout + Background
.homeFeeds .homeFeed:first-of-type {
  padding-top: #{$spacer * 2};

  &:after { 
    top: 50%;
    height: 50%;
    background-color: $grey-lightest;
  }

  &:before {
    bottom: 50%;
    height: 100%;
    background-image: url($assets-path + "curve-bottom-grey-2.png");
    background-size: 100.5% auto;
    background-position: bottom -1.5px center;
    z-index: -1;
    transform: translateX(-50%);
  }
}


// Stats - Background
// .homefeaturecategory-homeboximpactstats {
//   z-index: 1;
//   &:before, &:after {
//     content: '';
//     position: absolute;
//     height: 50%;
//     width: 101vw;
//     left: 50%;
//     transform: translateX(-50%);
//     background-size: 100.5% auto;
//     background-repeat: no-repeat;
//     z-index: -1;
//   }

//   &:not(.impactstatsAfterStoties):before {
//     bottom: 100%;
//     background-position: bottom -1.5px center;
//     background-image: url($assets-path + "curve-bottom-blue-dark.png");
//   }
  
//   &:after {
//     content: '';
//     top: 100%;
//     background-position: top -1.5px center;
//     background-image: url($assets-path + "curve-top-blue-dark.png");
//   }
// }


// Homepage quick giving panel - Format
.homefeaturecategory-homeboxquickgiving {
  border-radius: $border-radius;
  // border-radius: 38% 62% 49% 51% / 51% 67% 33% 49%;
  text-align: center;
  z-index: 1;
  // Layout
  .homepage-quickgiving--container {
    min-height: 260px;
    flex-direction: column;
  }
  .homepage-quickgiving--copy {
    margin-right: 0;
    margin-bottom: #{$spacer * 1.5};
  }

  @media (max-width: map-get($breakpoints, md)) {
    width: calc(100% - #{$site-bleed});
    padding-top: #{$spacer * 3};
    padding-bottom: #{$spacer * 3};
    .quickGivingPanel {
      padding-left: #{$spacer};
      padding-right: #{$spacer};
    }
  }
}


// Quick Givig Panel - Selected format
.quickGivingPanel .formQuestion { outline: none; }
.quickGivingPanel .formQuestion.donationSelected {
  box-shadow: inset 0 0 0 3px $home-quick-giving-donation-amount-selected-figure-colour;
}


// Footer logo - Format
.footerBox img.logoWhite {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: $spacer;
}


// Footer box - Format
.footerBox {
  position: relative;
  z-index: 1;
}


// Post - Spacing
.contentBlockWrapper {
  margin-bottom: 0;
  padding-bottom: #{$spacer * 3};
}


// Donation form - Layout
.formQuestion.donationPeriod {
  margin-top: 30px;
  border-radius: $border-radius $border-radius 0 0;
}


// Donation form - Format
.formQuestion.donationFrequency {
  #Single { border-radius: $border-radius 0 0 $border-radius; }   
  #Regular { border-radius: 0 $border-radius $border-radius 0; }
  &.frequencySelected { background-color: transparent; }
}


// Search page
.searchListingPage .listing {
  background-color: $post-body-background-colour;
}


div#mc_embed_signup {
  max-width: $container-max-width;
  padding: 0 20px;
  margin: auto;
  @media (min-width: 768px) {
    .mc-field-group-wrapper {
      display: flex;
      flex-direction: row;
    }
  }
  .mc-field-group {
    margin: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
  }
  input#mc-embedded-subscribe {
    background: $_orange;
    color: $_blue-dark;
    &:hover {
      background: $_red;
      color: white;
    }
  }
}

//BUG 308808 CC
.donorsListWrapper{
  display: none;
}